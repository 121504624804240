<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="名称">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
        <el-button type="warning" size="small" @click="rsyncDraft">同步草稿</el-button>
      </el-form-item>
    </el-form>
    <el-table
          :data="tableData"
          ref="rfTable"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange">
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="version"
          label="版本"
          width="150">
      </el-table-column>
      <el-table-column
          prop="desc"
          label="描述">
      </el-table-column>
      <el-table-column
              align="center"
          prop="draft_id"
          label="草稿id"
          width="150">
      </el-table-column>
      <el-table-column
          prop="tmplate_id"
          label="模板id"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.status==0" style="color: #333">草稿</div>
          <div v-if="scope.row.status==1" style="color: green">入库</div>
          <div v-if="scope.row.status==2" style="color: red">出库</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-popconfirm
              title="确定入库吗？"
              v-if="scope.row.status==0"
              @confirm="inAcc(scope.row)">
            <el-button type="text" size="small" slot="reference">入库</el-button>
          </el-popconfirm>
          <el-popconfirm
              title="确定删除吗？"
              v-if="scope.row.status==2"
              @confirm="delAcc(scope.row)">
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.wechat.wechatTemplateIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
    rsyncDraft() {
      this.showLoading()
      this.$api.wechat.rsyncDraft({}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.handleSearch()
          this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    inAcc(item) {
      this.showLoading()
      this.$api.wechat.addDraft({id: item.id}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.handleSearch()
          this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delAcc(item) {
      this.showLoading()
      this.$api.wechat.templateDel({id: item.id}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.handleSearch()
          this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
